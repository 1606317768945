// src/router/index.js
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Blog/BlogPage.vue'),
    meta: {
      id: 1,
      title: '首页'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/Blog/ListPage.vue'),
    meta: {
      title: '关于'
    }
  },
  {
    path: '/code',
    name: 'code',
    component: () => import('@/views/Blog/ListPage.vue'),
    meta: {
      id: 1,
      title: '编程'
    },
    children: [
      {
        path: 'C++/:tagId',
        name: 'C++',
        component: () => import('@/views/Blog/ListPage.vue'),
        meta: {
          id: 6,
          title: 'C++'
        },
      },
      {
        path: 'JAVA/:tagId',
        name: 'JAVA',
        component: () => import('@/views/Blog/ListPage.vue'),
        meta: {
          id: 8,
          title: 'JAVA'
        },
      },
      {
        path: 'VUE/:tagId',
        name: 'VUE',
        component: () => import('@/views/Blog/ListPage.vue'),
        meta: {
          id: 7,
          title: 'VUE'
        },
      }
    ]
  },
  {
    path: '/note',
    name: 'note',
    component: () => import('@/views/Blog/ListPage.vue'),
    meta: {
      title: '笔记'
    },
    children: [
      {
        path: 'study/:tagId',
        name: 'study',
        component: () => import('@/views/Blog/ListPage.vue'),
        meta: {
          id: 9,
          title: '学习笔记'
        },
      },
      {
        path: 'diary/:tagId',
        name: 'diary',
        component: () => import('@/views/Blog/ListPage.vue'),
        meta: {
          id: 10,
          title: '日记'
        },
      }
    ]
  }
];

export default routes;
