<!-- src/components/ThemeToggle.vue -->
<template>
  <button
    @click="handleClick"
    :class="['theme-toggle', { animate: isAnimating }]"
    class="bubbly-button"
  >
    <span v-if="isDarkMode">🌙</span>
    <span v-else>☀️</span>
  </button>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { useThemeStore } from '@/stores/theme'; // 引入 Pinia 的主题 store

export default {
  name: 'ThemeToggle',
  setup() {
    const themeStore = useThemeStore(); // 使用主题 store
    const isDarkMode = themeStore.isDarkMode;
    const toggleTheme = themeStore.toggleTheme;

    const isAnimating = ref(false);

    const handleClick = () => {
      toggleTheme();
      triggerAnimation();
    };

    const triggerAnimation = () => {
      isAnimating.value = true;
      setTimeout(() => {
        isAnimating.value = false;
      }, 700); // 动画持续时间
    };

    // 更新按钮和粒子的颜色
    const updateButtonColors = (isDark) => {
      const button = document.querySelector('.theme-toggle');
      if (!button) return;

      const lightColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--background-color')
        .trim();
      const darkColor = getComputedStyle(document.documentElement)
        .getPropertyValue('--text-color')
        .trim();

      if (isDark) {
        button.style.setProperty('--button-background', lightColor);
        button.style.setProperty('--particle-color', darkColor);
      } else {
        button.style.setProperty('--button-background', darkColor);
        button.style.setProperty('--particle-color', lightColor);
      }
    };

    onMounted(() => {
      themeStore.loadTheme(); // 加载主题
      updateButtonColors(isDarkMode.value); // 初始化按钮颜色
    });

    // 监控主题状态变化并更新按钮的颜色
    watch(isDarkMode, (newVal) => {
      updateButtonColors(newVal);
    });

    return {
      isDarkMode,
      isAnimating,
      handleClick,
    };
  },
};
</script>

<style scoped>
/* 主题切换按钮的基础样式 */
/* 保持不变 */
.theme-toggle {
  font-family: "Helvetica", "Arial", sans-serif;
  display: inline-block;
  font-size: 1em;
  padding: 1em;
  width: 60px;
  height: 60px;
  background-color: var(--button-background);
  color: var(--nav-text-color);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s, background-color 0.3s, color 0.3s;
  z-index: 1000;
}

.theme-toggle:focus {
  outline: none;
}

.theme-toggle:active {
  transform: scale(0.9);
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.1);
}

.theme-toggle.animate:before,
.theme-toggle.animate:after {
  content: "";
  display: block;
  position: absolute;
  width: 140%;
  height: 100%;
  left: -20%;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}

.theme-toggle.animate:before {
  top: -75%;
  background-image: radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, var(--particle-color) 20%, transparent 30%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--particle-color) 15%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}

.theme-toggle.animate:after {
  bottom: -75%;
  background-image: radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--particle-color) 15%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%),
    radial-gradient(circle, var(--particle-color) 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.theme-toggle.animate:before {
  animation: topBubbles ease-in-out 0.75s forwards;
}

.theme-toggle.animate:after {
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
</style>
