<template>
  <div id="app">
    <!-- 判断是否需要显示 ThemeToggle -->
    <ThemeToggle v-if="showThemeToggle" />
    <router-view></router-view>
  </div>
</template>

<script>
import ThemeToggle from './components/ThemeToggle.vue';

export default {
  name: 'App',
  components: {
    ThemeToggle
  },
  data() {
    return {
      showThemeToggle: true, // 默认显示
    };
  },
  watch: {
    // 监听路由变化，根据路径设置是否显示 ThemeToggle
    $route(to) {
      this.showThemeToggle = to.path !== '/login'; // 在登录页面不显示 ThemeToggle
    },
  },
  mounted() {
    // 初始化时检查路径
    this.showThemeToggle = this.$route.path !== '/login';
  },
};
</script>

<style>
#app {
  /* 样式配置 */
}
</style>
