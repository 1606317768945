// src/stores/theme.js
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useThemeStore = defineStore('theme', () => {
  const isDarkMode = ref(false);

  // 加载主题
  const loadTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      isDarkMode.value = true;
      document.body.classList.add('dark-mode');
    } else {
      isDarkMode.value = false;
      document.body.classList.remove('dark-mode');
    }
  };

  // 切换主题
  const toggleTheme = () => {
    isDarkMode.value = !isDarkMode.value;
    if (isDarkMode.value) {
      document.body.classList.add('dark-mode');
      localStorage.setItem('theme', 'dark');
    } else {
      document.body.classList.remove('dark-mode');
      localStorage.setItem('theme', 'light');
    }
    // 触发自定义事件通知主题变化
    const event = new Event('themeChanged');
    window.dispatchEvent(event);
  };

  // 监控主题状态变化，更新按钮颜色（可选，根据需求决定是否放在 store 里）
  watch(isDarkMode, (newVal) => {
    const button = document.querySelector('.theme-toggle');
    if (!button) return;

    const lightColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--background-color')
      .trim();
    const darkColor = getComputedStyle(document.documentElement)
      .getPropertyValue('--text-color')
      .trim();

    if (newVal) {
      button.style.setProperty('--button-background', lightColor);
      button.style.setProperty('--particle-color', darkColor);
    } else {
      button.style.setProperty('--button-background', darkColor);
      button.style.setProperty('--particle-color', lightColor);
    }
  });

  return {
    isDarkMode,
    loadTheme,
    toggleTheme,
  };
});
